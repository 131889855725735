const BASE_URL = 'http://45.80.69.141:60606';

export const ADMIN_TECHNOLOGY = `${BASE_URL}/admin/technology`;

export const ADMIN_CRITERIA = `${BASE_URL}/admin/criteria`;
export const ADMIN_CRITERIA_DEFAULT = `${BASE_URL}/admin/criteria/default`;

export const ADMIN_QUESTIONNAIRE_LAST = `${BASE_URL}/admin/questionnaire/last`;
export const ADMIN_QUESTIONNAIRE = `${BASE_URL}/admin/questionnaire/`;
export const ADMIN_RESET_TO_DEFAULT_QUESTIONNAIRE = `${BASE_URL}/admin/questionnaire/last-with-default`;
export const ADMIN_QUESTIONNAIRE_PASSED = `${BASE_URL}/admin/questionnaire/is-day-passed`;

export const USER_QUESTIONNAIRE = `${BASE_URL}/user/questionnaire`;

export const ADMIN_PROJECTS = `${BASE_URL}/admin/project`;
export const ADMIN_USERS = `${BASE_URL}/admin/employee`;
export const ADMIN_TASK = `${BASE_URL}/admin/task`;

export const PROJECTS = `${BASE_URL}/user/project`;
export const USERS = `${BASE_URL}/user/employee`;
export const USER_TASK = `${BASE_URL}/user/task`;
export const TASKS = `${BASE_URL}/user/task`;

export const REGISTER = `${BASE_URL}/public/register`;
export const LOGIN = `${BASE_URL}/public/auth`;

export const EVALUATIONS = `${BASE_URL}/user/evaluations`;
export const EVALUATIONS_LIST_ASSESS = `${BASE_URL}/user/evaluations/assess-list`;
export const EVALUATIONS_LIST_ASSESSED = `${BASE_URL}/user/evaluations/assessed-list`;
export const ADMIN_EVALUATIONS = `${BASE_URL}/admin/evaluations`;
export const ADMIN_ASSESSED = `${BASE_URL}/admin/assessed`;

export const ADMIN_USER_QUESTIONNAIRE_LIST = `${BASE_URL}/admin/list-questionnaire`;
export const ADMIN_RATING = `${BASE_URL}/admin/rating`;
export const ADMIN_STAT_POINTS = `${BASE_URL}/admin/stat/points/employee`;

export const USER_RATING = `${BASE_URL}/user/evaluations/rating`;
export const USER_STAT_POINTS = `${BASE_URL}/user/stat/points`;
export const USER_QUESTIONNAIRE_LIST = `${BASE_URL}/user/evaluations/list-questionnaire`;
export const USER_EVALUATIONS = `${BASE_URL}/user/evaluations`;
export const USER_EVALUATIONS_ASSESSED = `${BASE_URL}/user/evaluations/assessed`;

export const EMPLOYEE_ME = `${BASE_URL}/user/employee/me`;

export const USER_EMPLOYEE_ME = `${BASE_URL}/user/task/all-employees`;

export const LIST_YEARS_RATINGS = `${BASE_URL}/user/employee/list-of-years`;
export const LIST_OF_MONTHS_COMMAND = `${BASE_URL}/user/evaluations/rating/command`;
export const LIST_OF_MONTHS_PERSONAL = `${BASE_URL}/user/evaluations/rating/personal`;
export const LIST_OF_MONTH_USERS = `${BASE_URL}/admin/rating/personal`;

export const LIST_YEARS_DEADLINE_ADMIN = `${BASE_URL}/user/stat/task/team/years`;
export const LIST_MONTHS_DEADLINE_ADMIN = `${BASE_URL}/user/stat/task/team/`;
export const DATA_TEAM_DEADLINES_ADMIN = `${BASE_URL}/admin/stat/task/team`;
export const DATA_INDIVIDUAL_DEADLINES_ADMIN = `${BASE_URL}/admin/stat/task/individual`;

export const LIST_YEARS_DEADLINE_USER = `${BASE_URL}/user/stat/task/individual/years`;
export const LIST_MONTHS_DEADLINE_USER = `${BASE_URL}/user/stat/task/individual/`;
export const DATA_TEAM_DEADLINES_USER = `${BASE_URL}/user/stat/task/team`;
export const DATA_INDIVIDUAL_DEADLINES_USER = `${BASE_URL}/user/stat/task/individual`;
